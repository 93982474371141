<template>
  <el-container>
    <el-header>
      <h1 class="header-title">语法学习资源列表</h1>
    </el-header>
    <el-main>
      <el-row :gutter="20">
        <!-- 循环渲染每篇文章 -->
        <el-col v-for="article in grammarArticles" :key="article.id" :span="8" :xs="24" :sm="12" :md="8">
          <el-card class="article-card" @click="goToDetail(article.id)">
            <h2>{{ article.title }}</h2>
            <div v-html="getPreviewContent(article.content)"></div> <!-- 展示转换后的内容预览 -->
          </el-card>
        </el-col>
      </el-row>

      <!-- 加载更多按钮 -->
      <div class="load-more-container" v-if="hasMore">
        <el-button type="primary" @click="loadMore" :loading="isLoading">加载更多</el-button>
      </div>

      <!-- 没有更多数据的提示 -->
      <p v-else class="no-more-data">没有更多数据了</p>
    </el-main>
  </el-container>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { getAllArticles } from '@/api/learningArticles'; // 导入获取文章的方法
import { marked } from 'marked'; // 引入 Markdown 解析库

export default {
  setup() {
    const grammarArticles = ref([]); // 语法文章列表
    const pageNum = ref(1); // 当前页数
    const pageSize = ref(12); // 每页加载数量
    const hasMore = ref(true); // 是否还有更多数据
    const isLoading = ref(false); // 加载状态
    const router = useRouter(); // 路由实例

    // 加载文章数据
    const loadArticles = async () => {
      if (!hasMore.value || isLoading.value) return; // 如果没有更多数据或正在加载，直接返回
      isLoading.value = true; // 设置为加载中

      const params = {
        type: 3, // 语法类型
        pageNum: pageNum.value,
        pageSize: pageSize.value,
      };

      const response = await getAllArticles(params);
      if (response && response.data && response.data.records.length > 0) {
        grammarArticles.value.push(...response.data.records); // 将新数据追加到已有列表中
        if (grammarArticles.value.length >= response.data.total) {
          hasMore.value = false; // 如果没有更多数据，设置为false
        }
      } else {
        hasMore.value = false; // 没有数据时设置为false
      }

      isLoading.value = false; // 加载完成
    };

    // 点击“加载更多”时触发
    const loadMore = () => {
      pageNum.value++; // 页数增加
      loadArticles(); // 加载下一页数据
    };

    // 跳转到文章详情页
    const goToDetail = (id) => {
      router.push({ name: 'GrammarDetails', params: { id } });
    };

    // 将 Markdown 内容转换为 HTML
    const convertMarkdownToHTML = (content) => {
      return marked(content || '');
    };

    // 获取内容预览，限制行数并转换为 HTML
    const getPreviewContent = (content) => {
      const htmlContent = convertMarkdownToHTML(content);
      const previewLength = 100; // 设置预览字符数限制
      return htmlContent.length > previewLength ? htmlContent.substring(0, previewLength) + '...' : htmlContent;
    };

    onMounted(() => {
      loadArticles(); // 组件加载时加载文章数据
    });

    return {
      grammarArticles,
      hasMore,
      isLoading,
      loadMore,
      goToDetail,
      getPreviewContent,
    };
  },
};
</script>

<style scoped>
.article-card {
  height: 250px;  /* 设置固定高度 */
  overflow: hidden;  /* 超出部分隐藏 */
  position: relative;
  border-radius: 8px; /* 设置圆角，数值越大，圆角越明显 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 添加阴影效果提升视觉层次 */
  transition: box-shadow 0.3s ease; /* 添加平滑的阴影过渡效果 */
  margin-bottom: 10px; /* 卡片之间的间距 */
}

.article-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* 鼠标悬停时的阴影效果 */
}

.load-more-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.no-more-data {
  text-align: center;
  color: #999;
  margin-top: 20px;
}
.el-button {
  font-size: 17px;
  height: 45px;
}
</style>
