<template>
  <el-container>
    <el-header>
      <h1 class="header-title">新增学习资源</h1>
    </el-header>
    <el-main>
      <el-form :model="articleForm" ref="formRef" label-width="120px">
        <!-- 标题输入框 -->
        <el-form-item label="标题" prop="title" :rules="[{ required: true, message: '请输入标题', trigger: 'blur' }]">
          <el-input v-model="articleForm.title" placeholder="请输入标题"></el-input>
        </el-form-item>

        <!-- 内容输入框和预览 -->
        <el-row>
          <el-col :span="12" class="form-item">
            <el-form-item label="内容" prop="content" :rules="[{ required: true, message: '请输入内容', trigger: 'blur' }]">
              <textarea v-model="articleForm.content" placeholder="请输入内容" rows="15" class="content-input"></textarea>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="form-item">
            <el-form-item label="预览">
              <div v-html="compiledMarkdown" class="markdown-preview"></div> <!-- 实时预览Markdown内容 -->
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 类型选择框 -->
     <el-form-item label="类型" prop="type" :rules="[{ required: true, message: '请选择类型', trigger: 'change' }]">
        <el-select v-model="articleForm.type" placeholder="请选择类型">
          <el-option v-for="option in typeOptions" :key="option.value" :label="option.label" :value="option.value"></el-option>
        </el-select>
      </el-form-item>

        <!-- 视频地址输入框 -->
        <el-form-item label="视频地址" prop="video_url">
          <el-input v-model="articleForm.video_url" placeholder="请输入视频地址"></el-input>
        </el-form-item>

        <!-- 备注输入框 -->
        <el-form-item label="备注">
          <el-input v-model="articleForm.remarks" placeholder="请输入备注"></el-input>
        </el-form-item>

        <!-- 提交与重置按钮 -->
        <el-form-item>
          <el-button type="primary" @click="submitForm">提交</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </el-main>
  </el-container>
</template>

<script>
import { ref, computed } from 'vue';
import { ElMessage } from 'element-plus';  // 导入消息提示框
import { addArticle } from '../../api/learningArticles';  // 导入新增文章的 API
import { marked } from 'marked';  // 导入 marked 库，用于 Markdown 转换
import { useRouter } from 'vue-router'; // 导入 useRoute


export default {
  setup() {
    const articleForm = ref({
      title: '',
      content: '',
      remarks: '',
      type: '',  // 默认类型为空
      video_url: '',  // 视频地址
    });

    // 计算属性：实时将 Markdown 转换为 HTML
    const compiledMarkdown = computed(() => marked(articleForm.value.content || ''));
    const router = useRouter();
    // 提交表单
    const submitForm = async () => {
      try {
        // 调用 API 提交文章
        await addArticle(articleForm.value);
        // 提示成功消息
        ElMessage({
          message: '文章提交成功！',
          type: 'success',
        });
        // 清空表单
        resetForm();
        router.push({ path: `/resource/listhhxxttxs/` });
      } catch (error) {
        // 提示错误消息
        ElMessage.error('新增文章失败，请稍后重试！');
        console.error("新增文章失败:", error);
      }
    };

    const typeOptions = [
  { label: '基础', value: 1 },
  //{ label: '词汇', value: 2 },
 // { label: '语法', value: 3 },
  { label: '短语', value: 4 },
  { label: '句子', value: 5 }
  //,
 // { label: '文章学习', value: 6 }
];

    // 重置表单
    const resetForm = () => {
      articleForm.value = {
        title: '',
        content: '',
        remarks: '',
        type: '',
        video_url: '',
      };
    };

    return {
      articleForm,
      compiledMarkdown,  // 实时预览内容
      submitForm,
      typeOptions,
      resetForm,
    };
  },
};
</script>

<style scoped>
/* 设置输入框和预览框同样的大小 */
.content-input {
  width: 100%;
  height: 300px;
}

.markdown-preview {
  border: 1px solid #e0e0e0;
  padding: 10px;
  background-color: #f9f9f9;
  height: 300px;  /* 与输入框高度一致 */
  overflow-y: auto;
  white-space: pre-wrap;
  width: 100%;
}

.el-row .form-item {
  padding: 0 10px;  /* 两边输入框间距 */
}
</style>
