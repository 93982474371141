import { getRequest, postRequest } from '../utils/request'; // 导入封装的请求函数
import CONFIG from './config'; // 导入配置文件

const API_ENDPOINTS = {
  SELECT_ALL: '/learningArticles/selectAll',
  SELECT_ONE: (id) => `/learningArticles/selectOne?id=${id}`, // 修改为 GET 请求的 URL 格式
  ADD: '/learningArticles/add',
  UPDATE: '/learningArticles/update',
  DELETE: '/learningArticles/del',
  ADDMESSAGE: '/messages/add'
};

// 获取所有文章
export const getAllArticles = async (params) => {
  return await postRequest(CONFIG.BASE_URL + API_ENDPOINTS.SELECT_ALL, params);
};

// 根据 ID 获取单篇文章
export const getArticleById = async (id) => {
  return await getRequest(CONFIG.BASE_URL + API_ENDPOINTS.SELECT_ONE(id)); // 使用 getRequest
};

// 添加新文章
export const addArticle = async (articleData) => {
  return await postRequest(CONFIG.BASE_URL + API_ENDPOINTS.ADD, articleData);
};

// 添加留言
export const addMessage = async (messageData) => {
  return await postRequest(CONFIG.BASE_URL + API_ENDPOINTS.ADDMESSAGE, messageData);
};

// 更新文章
export const updateArticle = async (articleData) => {
  return await postRequest(CONFIG.BASE_URL + API_ENDPOINTS.UPDATE, articleData);
};

// 删除文章
export const deleteArticles = async (idList) => {
  return await postRequest(CONFIG.BASE_URL + API_ENDPOINTS.DELETE, idList);
};
