<template>
  <el-container>
    <!-- 这里放置全局导航栏 -->
    <el-header>
      <NavBar :activeIndex="activeIndex" :setActiveTab="setActiveTab" />
    </el-header>

    <!-- 主要内容区 -->
    <el-main class="el-main">
      <!-- 通过 router-view 动态渲染页面内容 -->
      <router-view />
    </el-main>
    
    <el-footer class="footer" style="color: #666;">
      <a href="https://beian.miit.gov.cn/" target="_blank">陕ICP备2024050940号</a>
    </el-footer>
  </el-container>
</template>

<script>
import { ref } from 'vue';
import NavBar from '../components/navbar/NavBar.vue';

export default {
  components: {
    NavBar,
  },
  setup() {
    const activeIndex = ref('home');  // 默认导航索引

    const setActiveTab = (tab) => {
      activeIndex.value = tab;
    };

    return {
      activeIndex,
      setActiveTab,
    };
  },
};
</script>

<style scoped>
el-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* 确保容器占满全屏 */
}

.el-main {
  flex: 1; /* 让主要内容区自动填充剩余空间 */
  padding-bottom: 80px; /* 给 footer 留出空间，确保内容不会被覆盖 */
}

.footer {
  text-align: center;
  padding: 20px 0;
  background-color: #f9f9f9;
  border-top: 1px solid #eaeaea;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0; /* 确保 footer 从左侧开始 */
}

.footer a {
  color: #666; /* 你想要的颜色 */
  text-decoration: none; /* 如果不想要下划线，可以加上这行 */
}
</style>
