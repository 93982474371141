<template>
  <el-container>
    <el-header>
      <h1 class="header-title">跟着六丽丽学英语，英语越来越流利！</h1>
    </el-header>
    <el-main>
      <el-row>
        <el-col :span="24">
          <div class="search-container">
            <el-input
              placeholder="搜索学习资源..."
              v-model="searchQuery"
              clearable
              class="search-input"
            ></el-input>
<el-button type="primary" :style="{ fontSize: '18px', height: '55px' }" @click="searchArticles">搜索</el-button>
          </div>
        </el-col>
      </el-row>

      <!-- 根据 showResults 控制文章列表的展示 -->
      <el-row v-if="showResults" :gutter="20">
        <el-col v-for="article in filteredArticles" :key="article.id" :span="8" :xs="24" :sm="12" :md="8">
          <el-card class="article-card" @click="goToDetail(article)">
            <div class="article-header">
              <span class="article-type">{{ getArticleType(article.type) }}</span>
            </div>
            <h2>{{ article.title }}</h2>
            <div v-html="getPreviewContent(article.content)"></div>
          </el-card>
        </el-col>
      </el-row>
      
      <el-button
        v-if="hasMore && showResults"
        type="primary"
        @click="loadMore"
        class="load-more-button"
      >
        加载更多
      </el-button>
      
    </el-main>
  </el-container>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import { getAllArticles } from '../../api/learningArticles';
import { marked } from 'marked';

export default {
  setup() {
    const searchQuery = ref('');  // 搜索查询
    const articles = ref([]);  // 所有文章列表
    const showResults = ref(false);  // 控制是否显示查询结果

    // 过滤后的文章
    const filteredArticles = computed(() => {
      return showResults.value ? articles.value : [];
    });

    const pageNum = ref(1);
    const pageSize = ref(6);
    const hasMore = ref(true);
    const router = useRouter();

    const loadArticles = async (title = '') => {
      if (!hasMore.value) return;  // 如果没有更多内容，不再加载
      const params = {
        title: title,
        pageNum: pageNum.value,
        pageSize: pageSize.value,
      };
      const response = await getAllArticles(params);
      if (response && response.data && response.data.records.length > 0) {
        articles.value.push(...response.data.records);
        if (articles.value.length >= response.data.total) {
          hasMore.value = false;  // 没有更多数据时停止加载
        }
      } else {
        hasMore.value = false;  // 没有数据时
      }
    };

    const searchArticles = () => {
      pageNum.value = 1;  // 重置为第一页
      articles.value = [];  // 清空当前文章列表
      hasMore.value = true;  // 重置加载状态
      showResults.value = true;  // 设置为显示查询结果
      loadArticles(searchQuery.value);  // 调用加载文章的方法
    };

    const loadMore = () => {
      pageNum.value++;  // 下一页
      loadArticles(searchQuery.value);
    };

    const goToDetail = (article) => {
      const { id, type } = article;
      let routeName = '';

      switch (type) {
        case 1:
          routeName = 'BasicDetails';
          break;
        case 2:
          routeName = 'VocabularyDetails';
          break;
        case 3:
          routeName = 'GrammarDetails';
          break;
        case 4:
          routeName = 'PhraseDetails';
          break;
        case 5:
          routeName = 'SentenceDetails';
          break;
        case 6:
          routeName = 'ArticleLearningDetails';
          break;
        default:
          routeName = 'UnknownDetails';
          break;
      }

      router.push({ name: routeName, params: { id } });
    };

    const convertMarkdownToHTML = (content) => {
      return marked(content || '');
    };

    const getPreviewContent = (content) => {
      const htmlContent = convertMarkdownToHTML(content);
      const previewLength = 100;
      return htmlContent.length > previewLength ? htmlContent.substring(0, previewLength) + '...' : htmlContent;
    };

    const getArticleType = (type) => {
      const types = {
        1: '基础',
        2: '词汇',
        3: '语法',
        4: '短语',
        5: '句子',
        6: '文章学习',
      };
      return types[type] || '未知类型';
    };

    onMounted(() => {
      loadArticles();
    });

    return {
      searchQuery,
      filteredArticles,
      loadMore,
      goToDetail,
      getPreviewContent,
      getArticleType,
      hasMore,
      searchArticles,
      showResults,  // 导出显示状态
    };
  },
};
</script>


<style scoped>
.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
}

.search-input {
  height: 55px;
  flex: 1;
  max-width: 600px;
  margin-right: 10px;
  font-size: 18px;
}

.el-button {
  font-size: 17px;
  height: 45px;
}

.article-card {
  height: 250px;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  margin-bottom: 10px; /* 保持与文章之间的间距 */
}

.article-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.article-header {
  background-color: #f0f0f0;
  padding: 10px;
}

.article-type {
  font-weight: bold;
}

.load-more-button {
  margin-top: 10px;  /* 调整加载更多按钮与上方元素的距离 */
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.el-row {
  margin-bottom: 20px; /* 调整el-row与下方元素的距离 */
}
</style>
