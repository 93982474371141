<template>
  <el-container>
    <el-header>
      <h1 class="header-title">关于我</h1>
    </el-header>
    <el-main>
      <section class="about-section">
        <p>
          大家好！我是一名热爱英语的学习者和分享者。在这个平台上，我希望与大家一起分享英语学习的知识和经验。学习英语不仅能帮助我们更好地沟通和理解世界，也为我们打开了更多的机会和可能性。我坚信，语言的学习是一个交流和分享的过程，每个人都可以在这个过程中找到自己的方式。
        </p>
        <p>
          在这个分享的平台上，我会定期发布有关英语学习的资源，包括语法、词汇、短语、句子结构等内容。同时，我也希望通过互动的方式，听到大家的想法和建议。无论你是英语学习的初学者，还是想提升自己的英语水平，都欢迎你来交流和分享。在这个过程中，我们不仅能学到新的知识，也能互相激励，共同成长。
        </p>
        <p>
          希望我们能一起探索英语的魅力，共同进步！感谢大家的关注与支持，期待你们的参与！
        </p>
      </section>
      
      <section class="message-board">
        <h2>留言板</h2>
        <el-form :model="messageForm" @submit.native.prevent="submitMessage">
          <el-form-item label="留言内容">
            <el-input type="textarea" v-model="messageForm.content" placeholder="在这里留言..."></el-input>
          </el-form-item>
          <el-form-item label="联系方式">
            <el-input v-model="messageForm.contact" placeholder="例如：邮箱或微信"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" native-type="submit">提交留言</el-button>
          </el-form-item>
        </el-form>
      </section>
    </el-main>
  </el-container>
</template>

<script>
import { ref } from 'vue';
import { ElMessage } from 'element-plus';  // 引入 Element Plus 的 ElMessage
import { addMessage } from '../../api/learningArticles';  // 导入新增留言的 API

export default {
  setup() {
    const messageForm = ref({ content: '', contact: '' });

    // 提交留言的方法
    const submitMessage = async () => {
      if (messageForm.value.content.trim() && messageForm.value.contact.trim()) {
        // 构造提交的数据对象
        const messageData = {
          content: messageForm.value.content.trim(),
          contactInfo: messageForm.value.contact.trim(),
        };

        try {
          // 调用后台 API 提交留言
          const response = await addMessage(messageData);
          if (response && response.data) {
            // 显示提交成功的消息提示
            ElMessage({
              message: '留言提交成功！',
              type: 'success',
              duration: 10000,  // 提示显示时间，3秒
              showClose: true  // 显示关闭按钮
            });
          }
        } catch (error) {
          // 如果提交失败，显示错误提示
          ElMessage({
            message: '提交留言失败，请稍后重试！',
            type: 'error',
            duration: 2000,
            showClose: true
          });
          console.error("提交留言失败", error);
        }

        // 重置表单
        messageForm.value.content = '';
        messageForm.value.contact = '';
      }
    };

    return {
      messageForm,
      submitMessage,
    };
  },
};
</script>

<style scoped>
.about-section {
  margin-bottom: 20px;
}

.message-board {
  border-top: 1px solid #eaeaea;
  padding-top: 20px;
}
.el-button {
  font-size: 17px;
  height: 45px;
}
</style>
