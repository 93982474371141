<template>
  <el-container>
    <el-header>
      <h1>学习资源列表</h1>
      <el-input v-model="searchQuery" placeholder="请输入标题查询" style="width: 300px; margin-right: 20px;"></el-input>
      <el-button type="primary" @click="fetchArticles">查询</el-button>
      <el-button type="primary" @click="goToAddPage">新增学习资源</el-button>
    </el-header>
    <el-main>
      <el-table :data="articles" style="width: 100%">
        <el-table-column prop="title" label="标题" width="180" />
        <el-table-column
          prop="type"
          label="类型"
          width="120"
          :formatter="formatType"
        />
        <el-table-column prop="video_url" label="视频地址" />
        <el-table-column label="操作" width="180">
          <template #default="scope">
            <el-button type="primary" size="small" @click="goToEditPage(scope.row.id)">修改</el-button>
            <el-button type="danger" size="small" @click="deleteArticle(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @current-change="handlePageChange"
        :current-page="pagination.page"
        :page-size="pagination.size"
        layout="total, prev, pager, next"
        :total="pagination.total">
      </el-pagination>
    </el-main>
  </el-container>
</template>

<script>
import axios from 'axios';
import { getAllArticles } from '../../api/learningArticles';
import { ref, onMounted } from 'vue';
import { ElMessage } from 'element-plus';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const articles = ref([]);
    const pagination = ref({
      page: 1,
      size: 10,
      total: 0,
    });
    const searchQuery = ref('');
    const router = useRouter();

    const fetchArticles = async () => {
      const params = {
        title: searchQuery.value,
        pageNum: pagination.value.page,
        pageSize: pagination.value.size,
      };
      try {
        const response = await getAllArticles(params);
        articles.value = response.data.records;
        pagination.value.total = response.data.total;
      } catch (error) {
        console.error('获取文章失败:', error);
        ElMessage.error('获取文章失败，请稍后重试！');
      }
    };

    const formatType = (row, column, cellValue) => {
      switch (cellValue) {
        case 1:
          return '基础';
        case 2:
          return '词汇';
        case 3:
          return '语法';
        case 4:
          return '短语';
        case 5:
          return '句子';
        case 6:
          return '文章学习';
        default:
          return '未知类型';
      }
    };

    const handlePageChange = (page) => {
      pagination.value.page = page;
      fetchArticles();
    };

    const goToEditPage = (id) => {
      router.push({ path: `/resource/edithhxxttxs/${id}` });
    };

    const goToAddPage = () => {
      router.push({ path: `/resource/addhhxxttxs/` });
    };

    const deleteArticle = async (id) => {
      try {
        await axios.post(`/lili/articles/delete`, { id });
        ElMessage.success('文章删除成功！');
        fetchArticles();
      } catch (error) {
        ElMessage.error('删除文章失败，请稍后重试！');
        console.error('删除文章失败:', error);
      }
    };

    onMounted(() => {
      fetchArticles();
    });

    return {
      articles,
      pagination,
      searchQuery,
      goToEditPage,
      goToAddPage,
      deleteArticle,
      handlePageChange,
      fetchArticles,
      formatType, // 将 formatType 方法返回
    };
  },
};
</script>

<style scoped>
.el-header {
  background-color: #f2f2f2;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.el-table {
  margin-bottom: 20px;
}
</style>
