<template>
  <el-container>
    <el-header>
      <h1 class="header-title">{{ article.title }}</h1>
    </el-header>
    <el-main>
      <div v-html="compiledContent" class="exportable-content"></div>
      <div v-if="article.videoUrl" class="video-container">
        <iframe
          :src="getEmbeddedVideoUrl(article.videoUrl)"
          frameborder="0"
          allow="fullscreen; clipboard-write; encrypted-media; picture-in-picture"
          class="bilibili-video"
        ></iframe>
      </div>
      <el-divider></el-divider>
      <p>备注: {{ article.remarks || '无' }}</p>
      
      <!-- 使用一个容器包裹按钮并使用 Flexbox -->
      <div class="button-container">
        <el-button @click="goBack" class="back-button-bottom">返回</el-button>
        <el-button @click="exportToPDF" class="export-pdf-button">导出 PDF</el-button>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { getArticleById } from '../../api/learningArticles';  // 从接口获取数据
import { marked } from 'marked';
import html2pdf from 'html2pdf.js';

export default {
  setup() {
    const article = ref({});
    const route = useRoute();
    const router = useRouter();

    const loadArticle = async () => {
      const response = await getArticleById(route.params.id);
      if (response && response.data) {
        article.value = response.data;
      }
    };

    const getEmbeddedVideoUrl = (url) => {
      const videoIdMatch = url.match(/\/video\/(BV\w+)/);
      if (videoIdMatch) {
        const bvId = videoIdMatch[1];
        return `https://player.bilibili.com/player.html?bvid=${bvId}&page=1&quality=112&autoplay=0`;
      }
      return '';
    };

    const goBack = () => {
      router.back();
    };

    const compiledContent = computed(() => {
      return marked(article.value.content || '');
    });

    const exportToPDF = async () => {
      const element = document.querySelector('.exportable-content');
      const textToAppend = '<br><br><br>跟着六丽丽学英语，英语越来越流利！了解更多学习资源，请访问：www.6lili.com';
      const opt = {
        margin: 10,
        filename: `${article.value.title}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      };

      const newElement = document.createElement('div');
      newElement.innerHTML = element.innerHTML + `<span style="color: blue; font-size: 14px;">${textToAppend}</span>`;

      // 生成 PDF
      await html2pdf().from(newElement).set(opt).save();
    };

    onMounted(() => {
      loadArticle();
    });

    return {
      article,
      goBack,
      compiledContent,
      getEmbeddedVideoUrl,
      exportToPDF,
    };
  },
};
</script>

<style scoped>
.video-container {
  position: relative;
  width: 50%;
  height: 0;
  padding-bottom: 28%;
  overflow: hidden;
  margin: 0 auto;
}

.bilibili-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.button-container {
  display: flex;
  justify-content: flex-start; /* 控制按钮的水平排列方式 */
  gap: 20px; /* 按钮之间的间距 */
  margin-top: 20px;
}

.back-button-bottom {
  /* 可以根据需要调整按钮的样式 */
}

.export-pdf-button {
  /* 可以根据需要调整按钮的样式 */
}

.exportable-content {
  /* 可根据需要添加样式 */
}
.el-button {
  font-size: 17px;
  height: 45px;
}
</style>
