<template>
  <el-menu
    :default-active="activeIndex"
    class="el-menu-demo"
    mode="horizontal"
    @select="handleMenuSelect"
    style="font-size: 18px; font-weight: bold;" 
  >
    <el-menu-item index="home">首页</el-menu-item>
    <el-menu-item index="basic">基础</el-menu-item>
    <el-menu-item index="phrases">短语</el-menu-item>
    <el-menu-item index="sentences">句子</el-menu-item>
    <!-- <el-menu-item index="grammar">语法</el-menu-item> -->
    <el-menu-item index="about">关于我</el-menu-item> <!-- 添加 关于我 的页签 -->
  </el-menu>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';

const activeIndex = ref('home');
const route = useRoute();
const router = useRouter();

// 手动处理菜单选择和路由跳转
const handleMenuSelect = (index) => {
  activeIndex.value = index; // 更新菜单选项的激活状态

  switch (index) {
    case 'home':
      router.push('/');
      break;
    case 'basic':
      router.push('/basic');
      break;
    case 'phrases':
      router.push('/phrases');
      break;
    case 'sentences':
      router.push('/sentences');
      break;
    case 'grammar':
      router.push('/grammar');
      break;
    case 'about': // 添加 关于我 的跳转
      router.push('/about');
      break;
  }
};

// 监听路由变化，确保页面刷新时菜单状态同步
watch(
  () => route.path,
  (newPath) => {
    if (newPath === '/') {
      activeIndex.value = 'home';
    } else if (newPath.includes('basic')) {
      activeIndex.value = 'basic';
    } else if (newPath.includes('phrases')) {
      activeIndex.value = 'phrases';
    } else if (newPath.includes('sentences')) {
      activeIndex.value = 'sentences';
    } else if (newPath.includes('grammar')) {
      activeIndex.value = 'grammar';
    } else if (newPath.includes('about')) { // 处理 关于我 页面的激活状态
      activeIndex.value = 'about';
    }
  }
);
</script>

<style>
.el-menu-demo .el-menu-item {
  font-size: 16px; /* 字体大小 */
  font-weight: bold; /* 字体加粗 */
}
.el-menu-demo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; /* 导航栏占满整个宽度 */
  z-index: 1000; /* 确保导航栏位于最前 */
  background-color: white; /* 添加背景色避免透明 */
  border-bottom: 1px solid #eaeaea; /* 添加底部边框 */
}
</style>
