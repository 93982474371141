import { createRouter, createWebHistory } from 'vue-router';

// 引入全局布局和各页面组件
import AppLayout from '../layouts/AppLayout.vue';  // 全局布局组件
import Basic from '../views/learning/Basic.vue';  // 基础学习资源列表页面
import BasicDetail from '../views/learning/BasicDetail.vue';  // 基础详情页面
import Phrases from '../views/learning/Phrases.vue';  // 短语学习页面
import PhrasesDetail from '../views/learning/PhrasesDetail.vue';
import Sentences from '../views/learning/Sentences.vue';  // 句子学习页面
import SentencesDetail from '../views/learning/SentencesDetail.vue';
import Grammar from '../views/learning/Grammar.vue';  // 语法学习页面
import GrammarDetail from '../views/learning/GrammarDetail.vue';
import Home from '../views/home/Home.vue';
import AddResource from '../views/admin/AddResource.vue';  // 新增音标学习资源页面
import EditResource from '../views/admin/EditResource.vue';  // 修改音标学习资源页面
import ListResource from '../views/admin/ListResource.vue';  // 音标学习资源列表页面
import About from '../views/about/About.vue';


// 定义路由
const routes = [
  {
    path: '/',  // 根路径
    component: AppLayout,  // 使用全局布局
    children: [
      {
        path: '',  // 默认子路由，对应主页组件
        name: 'Home',
        component: Home,
      },    
      {
        path: 'resource/addhhxxttxs',  // 新增音标学习资源路径
        name: 'AddResource',
        component: AddResource,  // 渲染新增音标学习资源组件
      },   {
        path: 'resource/edithhxxttxs/:id',  // 新增音标学习资源路径
        name: 'EditResource',
        component: EditResource,  // 渲染新增音标学习资源组件
      },   {
        path: 'resource/listhhxxttxs',  // 新增音标学习资源路径
        name: 'ListResource',
        component: ListResource,  // 渲染新增音标学习资源组件
      },
      {
        path: 'basic',  // 基础学习资源列表路径
        name: 'Basic',
        component: Basic,  // 
      },
      {
        path: 'basic/:id',  // 音标详情页路径，带参数 id
        name: 'BasicDetails',
        component: BasicDetail,  // 渲染音标详情组件
      },
      {
        path: 'about',  // 关于页面路径
        name: 'About',
        component: About,  // 渲染关于组件
      },
      {
        path: 'phrases',  // 短语学习页面路径
        name: 'Phrases',
        component: Phrases,  // 渲染短语学习组件
      },
      {
        path: 'phrases/:id',  // 短语详情页路径，带参数 id
        name: 'PhrasesDetails',
        component: PhrasesDetail,  // 渲染短语详情组件
      },
      {
        path: 'sentences',  // 句子学习页面路径
        name: 'Sentences',
        component: Sentences,  // 渲染句子学习组件
      },
      {
        path: 'sentences/:id',  // 句子详情页路径，带参数 id
        name: 'SentencesDetails',
        component: SentencesDetail,  // 渲染句子详情组件
      },
      {
        path: 'grammar',  // 语法学习页面路径
        name: 'Grammar',
        component: Grammar,  // 渲染语法学习组件
      },
      {
        path: 'grammar/:id',  // 语法详情页路径，带参数 id
        name: 'GrammarDetails',
        component: GrammarDetail,  // 渲染语法详情组件
      },
    ],
  },
];

// 创建路由实例并使用 HTML5 历史记录模式
const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
