// request.js
import axios from 'axios';
import CONFIG from '../api/config'; // 修改为相对路径

const instance = axios.create({
  baseURL: CONFIG.BASE_URL, // 使用配置文件中的 BASE_URL
  withCredentials: true,
  timeout: 5000, // 请求超时设置
});

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 可以在这里添加请求头或其他配置
    return config;
  },
  (error) => {
    console.error('请求错误:', error);
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    return response.data; // 直接返回数据
  },
  (error) => {
    console.error('响应错误:', error);
    // 根据需要可以在这里处理错误信息
    return Promise.reject(error);
  }
);

// GET 请求
export const getRequest = async (endpoint) => {
  try {
    const response = await instance.get(endpoint);
    return response; // 返回数据
  } catch (error) {
    throw error; // 抛出错误以便后续处理
  }
};

// POST 请求
export const postRequest = async (endpoint, data) => {
  try {
    const response = await instance.post(endpoint, data);
    return response; // 返回数据
  } catch (error) {
    throw error; // 抛出错误以便后续处理
  }
};
